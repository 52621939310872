import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import List from './List';
import InfoIcon from './InfoIcon';
import './../styles/Dashboard.scss';
import { fuzzyMatch } from '../utils/fuzzyMatch';
import AppContext from '../contexts/AppContext';
import { convertMinToTime } from '../utils/timeConverter';
import EditorModal from './EditorModal';

function Dashboard({
  guestlist,
  confirmedGuestlistCount,
  taggedList,
  managerList,
  checkinsCount,
  guestlistCapacityCount,
  guestlistCapacityMax,
  guestlistCutoffTime,
  updateReservation,
  moveToTagged,
  moveFromTagged,
  barsByBusiness,
  selectedBarId,
  makeReservation,
  handleChangeBar,
  refreshReservations,
}: any) {
  const params: any = useParams();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [filteredGuestlist, setFilteredGuestlist] = useState(guestlist);
  const [filteredManagerList, setFilteredManagerList] = useState(managerList);
  const [filteredTaggedList, setFilteredTaggedList] = useState(taggedList);
  const editorModalRef = useRef<any>(null);
  const reservationLists = useMemo(
    () => [
      {
        name: 'Confirmed Guestlists',
        reservations: filteredGuestlist,
      },
      {
        name: 'Tagged Lists',
        reservations: filteredTaggedList,
      },
      {
        name: 'Manager Lists',
        reservations: filteredManagerList,
      },
    ],
    [filteredGuestlist, filteredTaggedList, filteredManagerList]
  );

  const handleChangeQuery = useCallback(
    (query: string) => {
      const newFilteredGuestlist = guestlist.filter((element: any) => {
        return fuzzyMatch(query, element.name);
      });
      setFilteredGuestlist(newFilteredGuestlist);

      const newFilteredManagerList = managerList.filter((element: any) => {
        return fuzzyMatch(query, element.name);
      });
      setFilteredManagerList(newFilteredManagerList);
      const newFilteredTaggedList = taggedList.filter((element: any) => {
        return fuzzyMatch(query, element.name);
      });
      setFilteredTaggedList(newFilteredTaggedList);
    },
    [guestlist, taggedList, managerList]
  );

  const onManagerModalClick = () => {
    editorModalRef.current?.handleShow();
  };

  const checkBarsLoaded = () => {
    if (barsByBusiness.length > 0) {
      navigate('/app/bar/' + barsByBusiness[0]?._id);
    }
  };
  // setInterval(refreshReservations, 30000);

  useEffect(() => {
    appContext.handleChangeQuery = handleChangeQuery;
  }, [appContext, handleChangeQuery]);

  useEffect(() => {
    if (!params.barId) {
      if (barsByBusiness.length > 0) {
        navigate('/app/bar/' + barsByBusiness[0]?._id);
      } else {
        setTimeout(checkBarsLoaded, 1000);
      }
    } else {
      handleChangeBar(params.barId);
    }
  }, [barsByBusiness]);

  return (
    <Container>
      <Row className="mt-4" noGutters>
        <Col>
          <Container style={{ marginTop: '1rem' }}>
            {/* Begin Status Components */}
            <Row>
              <Col lg="12">
                <h3 className="bar-name">
                  {barsByBusiness.find((bar: any) => bar._id === params.barId)?.name}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Row className="status-row">
                  <Col lg="6">
                    <h2 className="status-title">Guestlist Status</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <hr className="hr-break"></hr>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <InfoIcon
                      title="Submitted Guestlists"
                      value={filteredGuestlist.length + filteredTaggedList.length}
                    ></InfoIcon>
                  </Col>
                  <Col lg="6">
                    <InfoIcon title="Check-Ins" value={checkinsCount}></InfoIcon>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row className="status-row">
                  <Col lg="6">
                    <h2 className="status-title">Bot Variables</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <hr className="hr-break"></hr>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <InfoIcon
                      title="Guestlist Capacity"
                      value={`${guestlistCapacityCount}/${guestlistCapacityMax}`}
                    ></InfoIcon>
                  </Col>
                  <Col lg="6">
                    <InfoIcon
                      title="Guestlist Cutoff Time"
                      value={convertMinToTime(guestlistCutoffTime)}
                    ></InfoIcon>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* End Status Components */}

            {/* Begin List Components */}
            <Row>
              {reservationLists.map((reservationList, index) => (
                <Col key={index} lg="4">
                  <List
                    reservations={reservationList.reservations}
                    name={reservationList.name}
                    updateReservation={updateReservation}
                    moveToTagged={moveToTagged}
                    moveFromTagged={moveFromTagged}
                  ></List>
                </Col>
              ))}
            </Row>
            {/* End List Components */}
          </Container>
        </Col>
      </Row>
      <Button
        style={{ position: 'fixed', bottom: 10, left: 10 }}
        onClick={onManagerModalClick}
        className="add-list-button rounded-circle mobile"
      >
        +
      </Button>
      <EditorModal ref={editorModalRef} makeReservation={makeReservation} />
    </Container>
  );
}

export default Dashboard;
