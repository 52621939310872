import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Sidebar from './Sidebar';
import Lottie from 'react-lottie';
import animationData from '../lottie/loading.json';
import './../styles/App.scss';
import Dashboard from './Dashboard';
import { Route, Routes } from 'react-router-dom';
import QueuePage from './QueuePage';
import BotSettings from './BotSettings';
import BarSettings from './BarSettings';
import AccountSettings from './AccountSettings';
import withData from '../HOCs/withData';

class RouteWrapper extends React.Component<any, any> {
  public render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <Container className="no-padding-x" fluid>
        <Row noGutters>
          <div id="sidebar-wrapper">
            <Sidebar {...this.props}></Sidebar>
          </div>
          <Col id="page-content-wrapper">
            <Menu {...this.props} handleChangeBar={this.props.handleChangeBar} />
            {/* Display Loading Spinner */}
            {this.props.loading ? (
              <div>
                <Lottie options={defaultOptions} height={100} width={100} />
              </div>
            ) : (
              <Routes>
                <Route path={`/`} element={<Dashboard {...this.props} />} />
                <Route path={`bar/`} element={<Dashboard {...this.props} />} />
                <Route path={`bar/:barId`} element={<Dashboard {...this.props} />} />
                <Route path={`bar/:barId/queue`} element={<QueuePage {...this.props} />} />
                <Route path={`bar/:barId/botsettings`} element={<BotSettings {...this.props} />} />
                <Route path={`bar/:barId/accountsettings`} element={<AccountSettings />} />
                <Route path={`bar/:barId/barsettings`} element={<BarSettings {...this.props} />} />
              </Routes>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withData(RouteWrapper);
